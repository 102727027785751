
import styles from '../styles/leftContent.module.css'


function LeftContent({handleLeftOpen,isLeftOpen,isRightOpen}) {

    return (
        <div className={!isLeftOpen ? styles.wrapper : styles.openWrapper}>
            <div className={`${styles.halfCircleContainer} ${isRightOpen ? styles.moveLeft : ''}`}>
                <div className={styles.halfCircle} />
                <div className={styles.halfCircle} />
                <div className={styles.halfCircle} />
                <div className={styles.halfCircle} />
                <div className={styles.halfCircle} />
            </div>
            <div className={styles.logoContainer} onClick={handleLeftOpen}>
                <p className={styles.logo}>MARKETING<br/>CONSULTING</p>
            </div>
            {isLeftOpen && (
                <div className={styles.content} >
                    <p >coming soon...</p>
                </div>
            )}
        </div>
  );
}

export default LeftContent;
