import styles from '../styles/header.module.css'

function Header({isLeftOpen,isRightOpen,isContactOpen,isAboutOpen,handleContactOpen}) {
  return (
    <div className={styles.header}>
      <p className={styles.logo} style={isRightOpen ? {color: '#c97f7f'} : {} }>minor role Inc.</p>
      <a className={styles.button} style={isLeftOpen | isContactOpen | isAboutOpen ? {borderColor: 'var(--white)'} : isRightOpen ? {borderColor: '#c97f7f'} : {} } onClick={handleContactOpen}>
        <p className={styles.buttonText} style={isLeftOpen | isContactOpen | isAboutOpen ? {color: 'var(--white)'} : isRightOpen ? {color: '#c97f7f'} : {} }>CONTACT</p>
      </a>
    </div>

  );
}

export default Header;
