
import styles from '../styles/rightContent.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faApple,faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';

const Hexagon = ({icon}) => (
    <div className={styles.hexagon}>
        <div className={styles.hexagonInner}>
            <div className={styles.hexagonContent}>
                <FontAwesomeIcon icon={icon} className={styles.icon} />
            </div>
        </div>
    </div>
);

function RightContent({handleRightOpen,isRightOpen,isLeftOpen}) {
    return (
        <div className={isLeftOpen ? styles.hide : styles.display}>
            <div className={!isRightOpen ? styles.wrapper : styles.openWrapper}>
                <div className={styles.halfCircleContainer}>
                    <div className={styles.halfCircle} />
                    <div className={styles.halfCircle} />
                    <div className={styles.halfCircle} />
                    <div className={styles.halfCircle} />
                    <div className={styles.halfCircle} />
                </div>
                {isRightOpen &&(
                    <div className={styles.openContent}>
                        <h2>
                            {/* 『推し活をもっと可愛く、もっと楽しく』 */}
                        </h2>
                        <div className={styles.hexagonContainer}>
                            <a className={styles.hexagon1} href='https://apps.apple.com/jp/app/oshimoa/id6470130488' target='_blank' rel="noreferrer" >
                                <Hexagon icon={faApple} />
                            </a>
                            <a className={styles.hexagon2} href='https://oshimoa.com' target='_blank' rel="noreferrer" >
                                <Hexagon icon={faBookOpen} />
                            </a>
                            <a className={styles.hexagon3} href='https://instagram.com/oshimoa_jp' target='_blank' rel="noreferrer" >
                                <Hexagon icon={faInstagram}/>
                            </a>
                            <a className={styles.hexagon4} href='https://play.google.com/store/apps/details?id=com.oshimoa&hl=ja&pli=1' target='_blank' rel="noreferrer" > 
                                <Hexagon icon={faGooglePlay} />
                            </a>
                        </div>
                    </div>
                )}
                <div className={styles.logoContainer} onClick={handleRightOpen}>
                    <p className={styles.logo}>Oshimoa</p>
                </div>
        </div>
        </div>
  );
}

export default RightContent;
