import styles from '../styles/footer.module.css'

function Footer({isLeftOpen,isRightOpen,isContactOpen,isAboutOpen,handleAboutOpen}) {
  return (
    <div className={styles.footer}>
        <a className={styles.button} style={isRightOpen? {borderColor: '#c97f7f'} : {} }>
            <p className={styles.buttonText} style={isRightOpen? {color: '#c97f7f'} : {} } onClick={handleAboutOpen}>ABOUT US</p>
        </a>
        <p className={styles.copyRight} style={isLeftOpen | isContactOpen | isAboutOpen ? {color: 'var(--white)'} : isRightOpen ? {color: '#c97f7f'} : {}  }>Copyright © minor role Inc.All Rights Reserved.</p>
    </div>
  );
}

export default Footer;
