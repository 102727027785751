import { useState } from 'react';
import styles from '../styles/contact.module.css'

function Contact({ isContactOpen }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        position: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        alert("お問い合わせ内容が送信されました。")
        setFormData({
        name: '',
        email: '',
        company: '',
        position: '',
        message: ''
    })
        
    };

    return (
        <div className={isContactOpen ? styles.open : styles.close}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <h3>CONTACT</h3>
                    <p className={styles.subText}>- お問い合わせ -</p>
                    <p className={styles.caption}>こちらのフォームにお客様の情報を入力していただいた後、1営業日以内に担当者よりご連絡させていただきます。</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <label>
                        お名前*
                        <input type='text' name="name" value={formData.name} onChange={handleChange} placeholder='フルネームでこ記入ください。' required/>
                    </label>
                    <label>
                        メールアドレス*
                        <input type='email' name="email" value={formData.email} onChange={handleChange} placeholder='mail@minor-role.co.jp' required/>
                    </label>
                    <label>
                        会社名*
                        <input type='text' name="company" value={formData.company} onChange={handleChange} placeholder='株式会社minor role' required/>
                    </label>
                    <label>
                        役職*
                        <input type='text' name="position" value={formData.position} onChange={handleChange} placeholder='経営企画' required/>
                    </label>
                    <label>
                        お問い合わせ内容*
                        <textarea name="message" value={formData.message} onChange={handleChange} placeholder='お問い合わせ内容の詳細をご記入ください。' required/>
                    </label>
                    <button type="submit">送信する</button>
                </form>
            </div>
        </div>
    );
}

export default Contact;
