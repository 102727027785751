import { useState } from 'react';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import LeftContent from './components/leftContent';
import RightContent from './components/rightContent';
import Contact from './components/contact';
import About from './components/about';


function App() {
  const [isLeftOpen, setLeftOpen] = useState(false)
  const [isRightOpen, setRightOpen] = useState(false)
  const [isContactOpen, setContactOpen] = useState(false)
  const [isAboutOpen,setAboutOpen] = useState(false)

  const handleLeftOpen = (value) => {
    setLeftOpen(!value)
    setContactOpen(false)
    setRightOpen(false)
    setAboutOpen(false)
  } 

  const handleRightOpen = (value) => {
    setRightOpen(!value)
    setContactOpen(false)
    setLeftOpen(false)
    setAboutOpen(false)
  } 

  const handleContactOpen = (value) => {
    setContactOpen(!value)
    setLeftOpen(false)
    setRightOpen(false)
    setAboutOpen(false)
  } 

  const handleAboutOpen = (value) => {
    setAboutOpen(!value)
    setLeftOpen(false)
    setRightOpen(false)
    setContactOpen(false)
  } 
  
  return (
    <>
      <header>
        <Header isLeftOpen={isLeftOpen} isRightOpen={isRightOpen} isContactOpen={isContactOpen} isAboutOpen={isAboutOpen} handleContactOpen={() =>handleContactOpen(isContactOpen) } />
      </header>
      <main>
        <LeftContent handleLeftOpen={() =>handleLeftOpen(isLeftOpen) } isLeftOpen={isLeftOpen} isRightOpen={isRightOpen}/>
        <RightContent handleRightOpen={() => handleRightOpen(isRightOpen)} isRightOpen={isRightOpen} isLeftOpen={isLeftOpen} />
        <Contact isContactOpen={isContactOpen} />
        <About isAboutOpen={isAboutOpen} />
      </main>
      <footer>
        <Footer isLeftOpen={isLeftOpen} isRightOpen={isRightOpen} isContactOpen={isContactOpen} isAboutOpen={isAboutOpen} handleAboutOpen={() =>handleAboutOpen(isAboutOpen) }/>
      </footer>
    </>

  );
}

export default App;
