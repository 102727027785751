import styles from '../styles/about.module.css'

function About({isAboutOpen}) {
  return (
    <div className={isAboutOpen ? styles.open : styles.close}>
      <div className={styles.wrapper}>
        <div className={styles.company}>
          <h3>COMPANY</h3>
            <p className={styles.subText}>- 会社概要 -</p>
          <ul>
            <li>会社概要：<span>株式会社minor role</span></li>
            <li>代表名：<span>宇津木隼人</span></li>
            <li>住所：<span>埼玉県日高市大字高萩140番地1</span></li>
            <li>連絡先：<span>info@minor-role.co.jp</span></li>
            <li>資本金：<span>1180万円※資本準備金含む</span></li>
            <li>設立日：<span>2022年7月6日</span></li>
          </ul>
        </div>
        <div className={styles.about}>
          <h2>COLOR THE STORY FOR  ...</h2>
        </div>
      </div>
    </div>
  );
}

export default About;